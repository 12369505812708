import {
  SelectDetailField,
  SelectDetailFieldOption,
  TextDetailField,
} from '../../../shared/DetailField'
import { Box, Divider } from '@mui/material'
import { DetailProps } from '../../../shared/CRUDContainer'
import { Photo } from 'types'
import { CardDetailFormWrapper } from '../../../shared/CardDetail'
import { PhotoWithUsedPages } from './PhotoManagement'

const selectOptions: { [key: string]: SelectDetailFieldOption[] } = {
  publishedStatus: [
    { label: 'Draft', value: 'DRAFT' },
    { label: 'Published', value: 'PUBLISHED' },
  ],
}

function PhotoManagementFields(props: {
  editMode: boolean
  values: Photo
  setFieldValue: (field: string, value: any) => void
}) {
  return (
    <Box style={{ display: 'flex' }}>
      <Box style={{ width: '300px' }}>
        {props.editMode && (
          <TextDetailField
            editMode={props.editMode}
            label="Title"
            name="title"
          />
        )}
        <TextDetailField
          editMode={props.editMode}
          label="Description"
          name="description"
          multline
        />
        <TextDetailField
          editMode={props.editMode}
          label="F Number"
          name="fNumber"
        />
        <TextDetailField
          editMode={props.editMode}
          label="Exposure"
          name="exposureTime"
        />
        <TextDetailField editMode={props.editMode} label="ISO" name="iso" />
        <TextDetailField
          editMode={props.editMode}
          label="Focal Length"
          name="focalLength"
        />
        <TextDetailField
          editMode={props.editMode}
          label="Photo Datetime"
          name="dateTimeOriginal"
        />
        <TextDetailField
          editMode={props.editMode}
          label="Body Model"
          name="bodyModel"
        />
        <TextDetailField
          editMode={props.editMode}
          label="Lens Model"
          name="lensModel"
        />
        <SelectDetailField
          editMode={props.editMode}
          label="Published Status"
          name="publishedStatus"
          options={selectOptions.publishedStatus}
        />
        <TextDetailField
          editMode={props.editMode}
          label="Update Time"
          name="dateTimeOriginal"
        />
      </Box>
      <Box sx={{ margin: '0 15px' }}>
        <Divider
          orientation="vertical"
          sx={{ opacity: 1, backgroundColor: 'grey' }}
        />
      </Box>

      <img
        src={`https://jasonandfriends-beta-pictures-bucket.s3.amazonaws.com/processed/${props.values?.entryId}/fullsize_${props.values?.entryId}`}
        alt={props.values?.title}
        style={{
          maxWidth: 'calc(100% - 300px)',
          height: 'auto',
        }}
      />
    </Box>
  )
}

export function PhotoManagementDetail(props: DetailProps<PhotoWithUsedPages>) {
  return (
    <CardDetailFormWrapper<PhotoWithUsedPages>
      {...props}
      itemName="photo"
      initialValues={{}}
      FormFieldsComponent={PhotoManagementFields}
      mandatoryFields={[
        'title',
        'fNumber',
        'exposureTime',
        'iso',
        'focalLength',
        'bodyModel',
        'lensModel',
        'publishedStatus',
      ]}
    />
  )
}
