import { Box, IconButton, Typography } from '@mui/material'
import { Photo } from 'types'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  KeyboardEvent,
} from 'react'
import { fetchPhoto } from '../../shared/api'
import { currentStage } from 'config'
import {
  datetimeDisplay,
  useComponentSizeAndPosition,
  useIsMobile,
} from '../../shared/utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { FadeInModal } from '../../shared/FadeInModal'
import CloseIcon from '@mui/icons-material/Close'

const PhotoDetailContext = createContext<{
  viewPhoto: (photoId: string) => void
  setAvailablePhotos: (photoIds: string[]) => void
}>({
  viewPhoto: () => {},
  setAvailablePhotos: () => {},
})

export const usePhotoDetail = () => useContext(PhotoDetailContext)

export const PhotoDetailProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [availablePhotos, setAvailablePhotos] = useState<string[]>([])

  const setViewedPhoto = useCallback(
    (photoId: string) => {
      navigate(`${location.pathname}?photo=${photoId}`)

      if (!availablePhotos.includes(photoId)) {
        setAvailablePhotos([...availablePhotos, photoId])
      }
    },
    [availablePhotos, location.pathname, navigate]
  )

  const clearViewedPhoto = useCallback(() => {
    navigate(location.pathname)
  }, [location.pathname, navigate])

  const { viewedPhotoId, viewedPhotoIndex } = useMemo(() => {
    const viewedPhotoId = new URLSearchParams(location.search).get('photo')
    return {
      viewedPhotoId,
      viewedPhotoIndex: availablePhotos.findIndex(
        photoId => photoId === viewedPhotoId
      ),
    }
  }, [availablePhotos, location.search])

  const arrowKeyNavigation = useCallback(
    (e: KeyboardEvent) => {
      // Since available photos are set on click by clicking on a photo, when we arrive by deep link, there are no "available photos" to cycle through
      if (!availablePhotos.length) return

      if (e.key === 'ArrowRight') {
        const nextIndex =
          viewedPhotoIndex === availablePhotos.length - 1
            ? 0
            : viewedPhotoIndex + 1
        setViewedPhoto(availablePhotos[nextIndex])
      } else if (e.key === 'ArrowLeft') {
        const nextIndex =
          viewedPhotoIndex === 0
            ? availablePhotos.length - 1
            : viewedPhotoIndex - 1
        setViewedPhoto(availablePhotos[nextIndex])
      }
    },
    [availablePhotos, setViewedPhoto, viewedPhotoIndex]
  )

  return (
    <PhotoDetailContext.Provider
      value={{ viewPhoto: setViewedPhoto, setAvailablePhotos }}
    >
      {viewedPhotoId && (
        <FadeInModal
          onClose={clearViewedPhoto}
          onKeyDown={arrowKeyNavigation}
          open
        >
          {/* Force remount with key */}
          <PhotoDetail
            selectedPhotoId={viewedPhotoId}
            key={viewedPhotoId}
            onClose={clearViewedPhoto}
          />
        </FadeInModal>
      )}
      {children}
    </PhotoDetailContext.Provider>
  )
}

export function PhotoDetail(props: {
  selectedPhotoId: string
  onClose: () => void
}) {
  const [photo, setPhoto] = useState<Photo | null>(null)
  const fetchSelectedPhotoDetails = useCallback(async () => {
    const photo = await fetchPhoto(props.selectedPhotoId)
    setPhoto(photo)
  }, [props.selectedPhotoId])

  useEffect(() => {
    fetchSelectedPhotoDetails()
  }, [props.selectedPhotoId, fetchSelectedPhotoDetails])

  const { boxRef, height, width } = useComponentSizeAndPosition()

  const [imageLoading, setImageLoading] = useState(true)

  /**
   * There's some weird resizing issues with the image when it first loads, so we'll add a delay to prevent the image from being displayed until it's ready
   */
  const [artificialDelayCompleted, setArtificialDelayCompleted] =
    useState(false)

  useEffect(() => {
    if (!imageLoading && !artificialDelayCompleted) {
      setTimeout(() => {
        setArtificialDelayCompleted(true)
      }, 100)
    }
  }, [artificialDelayCompleted, imageLoading])

  const isMobile = useIsMobile()

  /**
   * How this works:
   * object-fit: contain ensures the image is scaled to fit within the parent Box container while maintaining aspect ratio.
   * When the viewport is narrow, the maxWidth is set to 100% on the img component, so the calc(100% - 2rem) on the Box container is the width which is used.
   * When the viewport is wide, the maxHeight of the img component is set to calc(100vh - height - 2rem), so the height is used. There is no need to set a maxHeight on the Box container.
   * There is no "bottom" property field set, the calculated maxHeight of the inner img component handles that
   */

  return (
    <Box
      sx={{
        position: 'absolute',
        maxWidth: `calc(100% - ${isMobile ? 1 : 2}rem)`, // 100vw minus padding
        left: `calc((100vw - ${width}px) / 2)`, // Center horizontally
        // left: '1rem',
        top: isMobile ? '0.5rem' : '1rem',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 4,
        overflow: 'auto', // Enable scrolling for overflowing content
        opacity: imageLoading || !artificialDelayCompleted ? 0 : 1,
      }}
    >
      <IconButton
        sx={{ position: 'absolute', top: '1rem', right: '1rem' }}
        onClick={() => props.onClose()}
        disableRipple
      >
        <CloseIcon
          sx={{
            color: 'white',
            backgroundColor: 'rgba(160, 160, 160, 0.2)',
            boxShadow:
              'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px',
          }}
          fontSize={isMobile ? 'medium' : 'large'}
        />
      </IconButton>
      <Box
        component="img"
        src={`https://jasonandfriends-${currentStage}-pictures-bucket.s3.amazonaws.com/processed/${props.selectedPhotoId}/fullsize_${props.selectedPhotoId}`}
        alt={props.selectedPhotoId}
        sx={{
          maxWidth: '100%', // Constrain width to parent Box
          maxHeight: `calc(100vh - ${height}px - 2rem)`, // Constrain height to parent Box
          objectFit: 'contain', // Ensure aspect ratio is maintained
          // cursor: 'pointer',
        }}
        onLoad={() => setImageLoading(false)}
      />
      <Box ref={boxRef}>{photo && <DetailText photo={photo} />}</Box>
    </Box>
  )
}

function DetailText({ photo }: { photo: Photo }) {
  const exposureString = photo.exposureTime
    ? photo.exposureTime < 1
      ? `1/${1 / photo.exposureTime}"`
      : `${photo.exposureTime}"`
    : ``

  const isoString = photo.iso ? `ISO ${photo.iso}` : ``

  const focalLengthString = photo.focalLength ? `${photo.focalLength}mm` : ``

  const apertureString = photo.fNumber ? `f/${photo.fNumber}` : ``

  const bodyModelString = photo.bodyModel ? `${photo.bodyModel}` : ``

  const lensModelString = photo.lensModel ? `${photo.lensModel}` : ``

  const dateTimeString = photo.dateTimeOriginal
    ? datetimeDisplay(String(photo.dateTimeOriginal * 1000))
    : ``

  return (
    <div style={{ padding: '.5rem' }}>
      <Typography variant="h6">{photo.title}</Typography>
      {photo.description && (
        <Typography variant="body2">{photo.description}</Typography>
      )}
      {dateTimeString && (
        <Typography variant="body2">{dateTimeString}</Typography>
      )}

      <Typography variant="body2">
        {`${lensModelString} w/ ${bodyModelString} @ ${focalLengthString} ${apertureString} ${exposureString} ${isoString}`}
      </Typography>
    </div>
  )
}
