import './App.css'
import { BannerProvider } from './BannerProvider'
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom'
import { Admin } from './admin/Admin'
import {
  createTheme,
  responsiveFontSizes,
  ScopedCssBaseline,
  ThemeProvider,
} from '@mui/material'

import { Content } from './content/Content'
import { useIsMobile } from '../shared/utils'
import { useEffect } from 'react'

function ScrollToTop() {
  const { pathname } = useLocation()

  useEffect(() => {
    // Scroll to top when pathname changes
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function App() {
  console.log(process.env)
  const isMobile = useIsMobile()

  console.log({ isMobile })

  const theme = responsiveFontSizes(
    createTheme({
      typography: {
        fontFamily: 'avenir, sans-serif',
      },
      palette: {
        background: {
          default: 'rgb(250, 248, 241)',
        },
      },
    })
  )

  return (
    <ThemeProvider theme={theme}>
      <BannerProvider>
        <BrowserRouter>
          <ScrollToTop />

          <Routes>
            <Route path="/admin/*" element={<Admin />} />
            <Route
              path="/*"
              element={
                <ScopedCssBaseline>
                  <Content />
                </ScopedCssBaseline>
              }
            />
          </Routes>
        </BrowserRouter>
      </BannerProvider>
    </ThemeProvider>
  )
}

export default App
