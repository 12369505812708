/* eslint-disable array-callback-return */
import { useCallback, useMemo } from 'react'
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Radio,
  Typography,
} from '@mui/material'
import { DragEndEvent, useDroppable } from '@dnd-kit/core'
import DeleteIcon from '@mui/icons-material/Delete'
import { v4 as uuidv4 } from 'uuid'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'

import { Page, PageContent, PageElement } from 'types'
import { FormikErrors, useFormikContext } from 'formik'
import { PageElementDetail } from './pageElementDetails/PageElementDetail'
import {
  DndContextWithSensor,
  DraggableElement,
  SortableElement,
} from '../../../../shared/dnd'

function generatePageElementFieldSyntheticId(
  element: PageElement,
  field: string
) {
  return `content#${element.id}#${field}`
}

/**
 * A bit hacky because synthetic keys which do not exist on Page are used to set errors and touched state of the nested page element fields.
 */
export function PageElementEditorField(props: { editMode: boolean }) {
  const formik = useFormikContext<Page>()
  const content = formik.values.content

  const updateContent = useCallback(
    (content: PageContent) => {
      formik.setFieldValue('content', content)
    },
    [formik]
  )

  const addTextPageElement = useCallback(
    (index: number) => {
      const newContent = content.slice()
      newContent.splice(index, 0, {
        selected: content.length === 0,
        type: 'TEXT',
        name: 'New text element',
        text: '',
        id: uuidv4(),
      })
      updateContent(newContent)
    },
    [content, updateContent]
  )

  const addGalleryPageElement = useCallback(
    (index: number) => {
      const newContent = content.slice()
      newContent.splice(index, 0, {
        selected: content.length === 0,
        type: 'GALLERY',
        name: 'New gallery element',
        photoIds: [],
        id: uuidv4(),
      })
      updateContent(newContent)
    },
    [content, updateContent]
  )

  const addTripleImagePageElement = useCallback(
    (index: number) => {
      const newContent = content.slice()
      newContent.splice(index, 0, {
        selected: content.length === 0,
        type: 'TRIPLE_IMAGE',
        name: 'New triple image element',
        photoIds: [],
        text: 'Placeholder text',
        id: uuidv4(),
      })
      updateContent(newContent)
    },
    [content, updateContent]
  )

  const [selectedPageElement, selectedPageElementErrors] = useMemo(() => {
    const selectedElement = content.find(e => e.selected)
    if (!selectedElement) {
      return [undefined, {}]
    }

    // Filter formik errors object to only include errors for the selected element which also have been touched by the user or if the form has been submitted
    const errors: FormikErrors<PageElement> = (() => {
      if (!formik.errors) {
        return {}
      }

      return Object.keys(formik.errors).reduce((acc, key) => {
        // if key starts with proper prefix
        if (
          key.startsWith(`content#${selectedElement.id}`) &&
          (formik.touched[key as keyof Page] || formik.submitCount > 0)
        ) {
          // Extract field name as the last split of -
          const fieldName = key.split('#')[2]
          acc[fieldName] = (formik.errors as any)[key]
        }

        return acc
      }, {} as Record<string, string>)
    })()

    return [selectedElement, errors]
  }, [content, formik.errors, formik.submitCount, formik.touched])

  const erroredPageElementIds = useMemo(() => {
    if (!formik.errors) {
      return new Set<string>()
    }

    return new Set<string>(
      Object.keys(formik.errors)
        .filter(
          k =>
            k.startsWith('content#') &&
            (formik.touched[k as keyof Page] || formik.submitCount > 0)
        )
        .map(key => key.split('#')[1])
    )
  }, [formik.errors, formik.submitCount, formik.touched])

  const onUpdatePageElementField = useCallback(
    (field: string, value: any) => {
      if (!selectedPageElement) {
        return
      }

      formik.setFieldTouched(
        generatePageElementFieldSyntheticId(selectedPageElement, field),
        true
      )
      updateContent(
        content.map(e =>
          e.id === selectedPageElement?.id ? { ...e, [field]: value } : e
        )
      )
    },
    [content, formik, selectedPageElement, updateContent]
  )

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      // Handle add new case
      const addFn = (type: string, index: number) => {
        switch (type) {
          case 'gallery-page-element':
            addGalleryPageElement(index)
            break
          case 'triple-image-page-element':
            addTripleImagePageElement(index)
            break
          case 'text-page-element':
            addTextPageElement(index)
            break
        }
      }

      // Handle drag to add case (event.over exists)
      if (
        [
          'gallery-page-element',
          'triple-image-page-element',
          'text-page-element',
        ].includes(String(event.active?.id)) &&
        String(event.over?.id).includes(
          'droppable-page-element-list-new-element'
        )
      ) {
        const index = (event.over?.data.current as any).index
        addFn(event.active?.id as string, index)
        return
      }

      // Handle click to add case
      if (
        [
          'gallery-page-element',
          'triple-image-page-element',
          'text-page-element',
        ].includes(String(event.active?.id))
      ) {
        addFn(event.active?.id as string, content.length)
        return
      }

      // Handle reordering and select case
      if (event.active && event.over) {
        const oldIndex = content.findIndex(
          element => element.id === event.active.id
        )
        const newIndex = content.findIndex(
          element => element.id === event.over!.id
        )

        if (oldIndex !== -1 && newIndex !== -1) {
          updateContent(arrayMove(content, oldIndex, newIndex))
        }

        if (event.over.id === event.active.id) {
          updateContent(
            content.map(e => ({
              ...e,
              selected: e.id === event.active.id,
            }))
          )
        }
      }
    },
    [
      addGalleryPageElement,
      addTextPageElement,
      addTripleImagePageElement,
      content,
      updateContent,
    ]
  )

  return (
    <Box style={{ display: 'flex', marginTop: '10px' }}>
      <Paper sx={{ padding: '10px', width: '350px', flexShrink: 0 }}>
        <DndContextWithSensor onDragEnd={handleDragEnd}>
          <Box>
            <Typography variant="h6">Page Elements</Typography>
            {props.editMode && (
              <>
                <Typography sx={{ fontSize: 16 }} gutterBottom>
                  Click or drag an element to add it to the page
                </Typography>
                <Box sx={{ display: 'flex' }}>
                  <DraggableElement id="gallery-page-element">
                    <Button variant="contained" sx={{ marginRight: '5px' }}>
                      Gallery
                    </Button>
                  </DraggableElement>

                  <DraggableElement id="triple-image-page-element">
                    <Button variant="contained" sx={{ marginRight: '5px' }}>
                      Triple Image
                    </Button>
                  </DraggableElement>

                  <DraggableElement id="text-page-element">
                    <Button variant="contained" sx={{ marginRight: '5px' }}>
                      Text
                    </Button>
                  </DraggableElement>
                </Box>
              </>
            )}
          </Box>

          <DroppablePageElementList
            editMode={props.editMode}
            pageElements={content}
            erroredPageElementIds={erroredPageElementIds}
            updateContent={updateContent}
          />
        </DndContextWithSensor>
      </Paper>
      <Box sx={{ width: '10px' }} /> {/* Box for padding only */}
      <Paper sx={{ padding: '10px', width: '100%' }}>
        <PageElementDetail
          element={selectedPageElement}
          editMode={props.editMode}
          errors={selectedPageElementErrors}
          onUpdateField={onUpdatePageElementField}
        />
      </Paper>
    </Box>
  )
}

function DroppablePageElementList(props: {
  editMode: boolean
  pageElements: PageContent
  erroredPageElementIds: Set<string>
  updateContent: (content: PageContent) => void
}) {
  const { active, setNodeRef } = useDroppable({
    id: `droppable-page-element-list`,
  })

  const showNewElementDropZones =
    active &&
    [
      'gallery-page-element',
      'triple-image-page-element',
      'text-page-element',
    ].includes(String(active?.id))

  return (
    <List
      sx={{
        width: '100%',
        position: 'relative',
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
      ref={setNodeRef}
      subheader={
        props.editMode ? (
          <ListSubheader>Drag to reorder page elements</ListSubheader>
        ) : undefined
      }
    >
      <SortableContext
        disabled={!props.editMode}
        items={props.pageElements}
        strategy={verticalListSortingStrategy}
      >
        {props.pageElements.map((element, i) => {
          return (
            <Box key={i}>
              {showNewElementDropZones && (
                <DropPageElementComponent index={i} />
              )}

              <PageElementListItem
                element={element}
                editMode={props.editMode}
                isErrored={props.erroredPageElementIds.has(element.id)}
                onRemove={() => {
                  const newContent = props.pageElements.slice()
                  newContent.splice(i, 1)
                  props.updateContent(newContent)
                }}
                isDragged={element.id === active?.id}
                showTransition={!!active}
                onSelect={() => {
                  props.updateContent(
                    props.pageElements.map(e => ({
                      ...e,
                      selected: e.id === element.id,
                    }))
                  )
                }}
              />
            </Box>
          )
        })}
      </SortableContext>

      {showNewElementDropZones && (
        <DropPageElementComponent index={props.pageElements.length} />
      )}
    </List>
  )
}

function PageElementListItem(props: {
  element: PageElement
  editMode: boolean
  isErrored: boolean
  onRemove: () => void
  isDragged: boolean
  showTransition: boolean
  onSelect: () => void
}) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Radio
        checked={props.element.selected}
        onSelect={props.onSelect}
        onClick={props.onSelect}
      />
      <SortableElement
        showTransition={props.showTransition}
        id={props.element.id}
      >
        <ListItem selected={props.isDragged}>
          <ListItemText
            primary={props.element.name}
            primaryTypographyProps={{
              width: '240px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              color: props.isErrored ? 'rgb(211, 47, 47)' : undefined,
            }}
            secondary={props.element.type}
            secondaryTypographyProps={
              props.isErrored
                ? {
                    color: 'rgb(211, 47, 47)',
                  }
                : undefined
            }
          />
        </ListItem>
      </SortableElement>

      {props.editMode && (
        <IconButton aria-label="delete" onClick={props.onRemove}>
          <DeleteIcon />
        </IconButton>
      )}
    </Box>
  )
}

function DropPageElementComponent(props: { index: number }) {
  const { isOver, setNodeRef } = useDroppable({
    data: { index: props.index },
    id: `droppable-page-element-list-new-element-${props.index}`,
  })
  return (
    <Box
      ref={setNodeRef}
      sx={{
        height: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '4px',
        border: isOver ? '1px solid darkgrey' : '1px dashed lightgrey',
        cursor: isOver ? 'pointer' : 'default',
      }}
    >
      <Typography sx={{ fontSize: 16 }} gutterBottom>
        Drop to add page element
      </Typography>
    </Box>
  )
}
