import { Box, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useIsMobile } from '../../shared/utils'

export function Footer() {
  const footerRef = useRef<HTMLDivElement>(null)
  const [footerHeight, setFooterHeight] = useState(0)

  useEffect(() => {
    if (footerRef.current) {
      setFooterHeight(footerRef.current.offsetHeight)
    }
  }, [])

  const isMobile = useIsMobile()

  return (
    <>
      {!isMobile && <Box sx={{ minHeight: `${footerHeight}px` }} />}
      <Box
        sx={{
          padding: isMobile ? '10px' : '20px',
          textAlign: 'center',
          position: isMobile ? 'inherit' : 'fixed',
          bottom: 0,
          width: '100%',
          backgroundColor: 'rgb(250, 248, 241)',
        }}
        ref={footerRef}
      >
        <Typography variant="subtitle2">
          Made lovingly by{' '}
          <a
            href="https://github.com/jasonou1994/jasonandfriends-2022"
            target="_blank"
            rel="noopener noreferrer"
          >
            hand
          </a>
          .
        </Typography>

        <Typography variant="subtitle2">
          © 2025 Jason Ou. All rights reserved.
        </Typography>
      </Box>
    </>
  )
}
