import { Box, Typography } from '@mui/material'
import { currentStage } from 'config'
import {
  GalleryPageElement,
  Page,
  TextPageElement,
  TripleImagePageElement,
} from 'types'
import { VerticalFlexGallery } from '../../shared/VerticalFlexGallery'
import { useWindowSize } from '../../shared/useWindowSize'
import { useMemo } from 'react'
import { usePhotoDetail } from './PhotoDetail'
import { useIsMobile } from '../../shared/utils'

export function PageComponent({
  page,
  showTitle = true,
  showDate = false,
  // targetWindow is used to measure the width of the window. When rendering the live preview during page creation, we pass in the window object of the secondary window.
  targetWindow = window,
}: {
  page: Page
  showTitle?: boolean
  showDate?: boolean
  targetWindow?: Window
}) {
  return (
    <Box>
      {showDate && (
        <Box sx={{ marginBottom: '10px' }}>
          <Typography variant="h6">
            {new Date(Number(page.updateTime)).toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </Typography>
        </Box>
      )}

      {showTitle && (
        <Box>
          <Typography variant="h2">{page.title}</Typography>
        </Box>
      )}

      {page.content.map((element, index) => {
        switch (element.type) {
          case 'GALLERY':
            return (
              <GalleryComponent
                key={index}
                gallery={element}
                targetWindow={targetWindow}
              />
            )
          case 'TRIPLE_IMAGE':
            return (
              <TripleImageComponent
                key={index}
                tripleImage={element}
                targetWindow={targetWindow}
              />
            )
          case 'TEXT':
            return <TextComponent key={index} text={element} />
          default:
            return null
        }
      })}
    </Box>
  )
}

function GalleryComponent({
  gallery,
  targetWindow = window,
}: {
  gallery: GalleryPageElement
  targetWindow?: Window
}) {
  const { viewPhoto, setAvailablePhotos } = usePhotoDetail()

  const availablePhotos = useMemo(() => {
    return gallery.photoIds
  }, [gallery.photoIds])

  const { innerWidth } = useWindowSize(targetWindow)
  const colummWidth = useMemo(() => {
    if (!innerWidth) {
      return 400
    }
    if (innerWidth > 2500) {
      return 800
    }

    if (innerWidth > 1500) {
      return 600
    }

    return 400
  }, [innerWidth])

  return (
    <VerticalFlexGallery
      targetWindow={targetWindow}
      columnWidth={colummWidth}
      components={gallery.photoIds.map(photoId => (
        <img
          src={`https://jasonandfriends-${currentStage}-pictures-bucket.s3.amazonaws.com/processed/${photoId}/medium_${photoId}`}
          alt={photoId}
          style={{
            width: '100%',
            height: 'auto',
            display: 'block',
            cursor: 'pointer',
          }}
          onClick={() => {
            viewPhoto(photoId)
            setAvailablePhotos(availablePhotos)
          }}
        />
      ))}
    />
  )
}

function TripleImageComponent({
  tripleImage,
  targetWindow = window,
}: {
  tripleImage: TripleImagePageElement
  targetWindow?: Window
}) {
  const { viewPhoto, setAvailablePhotos } = usePhotoDetail()

  const isMobile = useIsMobile()

  return (
    <Box>
      <img
        src={`https://jasonandfriends-${currentStage}-pictures-bucket.s3.amazonaws.com/processed/${tripleImage.photoIds[0]}/medium_${tripleImage.photoIds[0]}`}
        alt={tripleImage.photoIds[0]}
        style={{ width: isMobile ? '100%' : '350px', cursor: 'pointer' }}
        onClick={() => {
          viewPhoto(tripleImage.photoIds[0])
          setAvailablePhotos(tripleImage.photoIds)
        }}
      />
      <img
        src={`https://jasonandfriends-${currentStage}-pictures-bucket.s3.amazonaws.com/processed/${tripleImage.photoIds[1]}/medium_${tripleImage.photoIds[1]}`}
        alt={tripleImage.photoIds[1]}
        style={{ width: isMobile ? '100%' : '350px', cursor: 'pointer' }}
        onClick={() => {
          viewPhoto(tripleImage.photoIds[1])
          setAvailablePhotos(tripleImage.photoIds)
        }}
      />
      <img
        src={`https://jasonandfriends-${currentStage}-pictures-bucket.s3.amazonaws.com/processed/${tripleImage.photoIds[2]}/medium_${tripleImage.photoIds[2]}`}
        alt={tripleImage.photoIds[2]}
        style={{ width: isMobile ? '100%' : '350px', cursor: 'pointer' }}
        onClick={() => {
          viewPhoto(tripleImage.photoIds[2])
          setAvailablePhotos(tripleImage.photoIds)
        }}
      />
      <p>{tripleImage.text}</p>
    </Box>
  )
}

function TextComponent(props: { text: TextPageElement }) {
  return <p>{props.text.text}</p>
}
