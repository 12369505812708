import { DndContextWithSensor } from '../../shared/dnd'
import { StickerBar } from '../../shared/sticker/StickerBar'
import { StickerProvider } from '../../shared/sticker/StickerProvider'
import { ContentRouter } from './ContentRouter'
import { PagesProvider } from './PageProvider'
import { PhotoDetailProvider } from './PhotoDetail'

export function Content() {
  return (
    <StickerProvider>
      <PhotoDetailProvider>
        <PagesProvider>
          <DndContextWithSensor>
            <StickerBar />
            <ContentRouter />
          </DndContextWithSensor>
        </PagesProvider>
      </PhotoDetailProvider>
    </StickerProvider>
  )
}
