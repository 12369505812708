import type { ReactElement } from 'react'
import { useCallback, useEffect } from 'react'

import {
  type CRUDFeatures,
  type CRUDProps,
  useCRUDWithDeepLinkingUtils,
} from './useCRUDWithDeepLinkingUtils'

export interface DetailProps<T> {
  item?: T
  onClose: () => void
  onSubmit: (item: T) => Promise<T | undefined>
  onDelete: (item: T) => Promise<boolean>
  loading: boolean
  setSelectedItem: (item?: T) => void
  detailBanner?: { content: string; isSuccess: boolean }
  isCreate: boolean
  setDetailBanner: (banner?: { content: string; isSuccess: boolean }) => void
}

export interface TableProps<T> {
  data: T[]
  onCreate?: () => void
  onRefresh: () => void
  onRowClick: (item: T) => void
  filterToBeDeleted: (rows: T[]) => T[]
  onDeleteRows: (items: T[]) => void
  loading: boolean
}

export interface ContentsProps<T> {
  listLoading: boolean
  detailLoading: boolean
  loadItems: () => void

  isCreate: boolean
  setCreate: (bool: boolean) => void

  onCreate: (item: T) => Promise<T | undefined>
  onDelete: (item: T) => Promise<boolean>
  filterToBeDeleted: (rows: T[]) => T[]
  onBulkDelete: (item: T[]) => Promise<boolean>
  onUpdate: (item: T) => Promise<T | undefined>

  data: T[]
  selectedItem?: T
  setSelectedItem: (item?: T) => void
  resetUrl: () => void
  onRowClick: (token: T) => void

  detailBanner?: {
    content: string
    isSuccess: boolean
  }
  setDetailBanner: (banner?: { content: string; isSuccess: boolean }) => void

  DetailComponent: (props: DetailProps<T>) => ReactElement<DetailProps<T>>
  TableComponent: (props: TableProps<T>) => ReactElement<TableProps<T>>

  features: CRUDFeatures
}

/**
 * A component that renders a table and a detail view. Should suit the majority of basic CRUD use cases.
 */
export function Contents<T>(props: ContentsProps<T>) {
  const onClose = useCallback(() => {
    if (props.isCreate) {
      props.setCreate(false)
    }
    if (props.selectedItem) {
      props.resetUrl()
    }
    props.setSelectedItem(undefined)
    props.setDetailBanner(undefined)
  }, [props])

  const showDetailComponent =
    props.selectedItem || props.isCreate || props.detailLoading

  const Detail = props.DetailComponent
  const Table = props.TableComponent

  return (
    <>
      {showDetailComponent && (
        <Detail
          detailBanner={props.detailBanner}
          isCreate={props.isCreate}
          item={props.selectedItem}
          loading={props.detailLoading}
          onClose={onClose}
          onDelete={props.onDelete}
          onSubmit={props.isCreate ? props.onCreate : props.onUpdate}
          setDetailBanner={props.setDetailBanner}
          setSelectedItem={props.setSelectedItem}
        />
      )}

      <div style={showDetailComponent ? { display: 'none' } : {}}>
        <Table
          data={props.data}
          loading={props.listLoading}
          onCreate={
            props.features.canCreate ? () => props.setCreate(true) : undefined
          }
          onRowClick={props.onRowClick}
          onRefresh={props.loadItems}
          onDeleteRows={props.onBulkDelete}
          filterToBeDeleted={props.filterToBeDeleted}
        />
      </div>
    </>
  )
}

export function CRUDContainer<T>({
  createFunction,
  deepLinkSearchParam,
  deleteFunction,
  filterToBeDeleted,
  bulkDeleteFunction,
  DetailComponent,
  itemDisplayName,
  itemIdKey,
  listFunction,
  retrievalFunction,
  skipSetCreateToFalseAfterCreate,
  TableComponent,
  updateFunction,
}: CRUDProps<T> & {
  DetailComponent: (props: DetailProps<T>) => ReactElement<DetailProps<T>>
  TableComponent: (props: TableProps<T>) => ReactElement<TableProps<T>>
}) {
  const {
    createItem,
    deleteItem,
    bulkDeleteItems,
    detailBanner,
    detailLoading,
    features,
    isCreate,
    items,
    listLoading,
    loadItems,
    pushToHistory,
    removeFromHistory,
    selectedItem,
    setCreate,
    setDetailBanner,
    setSelectedItem,
    updateItem,
  } = useCRUDWithDeepLinkingUtils<T>({
    createFunction,
    deepLinkSearchParam,
    deleteFunction,
    bulkDeleteFunction,
    itemDisplayName,
    itemIdKey,
    listFunction,
    retrievalFunction,
    skipSetCreateToFalseAfterCreate,
    updateFunction,
  })

  useEffect(() => {
    loadItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section style={{ marginTop: '10px' }}>
      <Contents<T>
        data={items}
        detailBanner={detailBanner}
        DetailComponent={DetailComponent}
        detailLoading={detailLoading}
        features={features}
        isCreate={isCreate}
        listLoading={listLoading}
        loadItems={loadItems}
        onCreate={createItem}
        onDelete={deleteItem}
        filterToBeDeleted={filterToBeDeleted || (items => items)}
        onBulkDelete={bulkDeleteItems}
        onRowClick={item => pushToHistory(String(item[itemIdKey]))}
        onUpdate={updateItem}
        resetUrl={removeFromHistory}
        selectedItem={selectedItem}
        setCreate={setCreate}
        setDetailBanner={setDetailBanner}
        setSelectedItem={setSelectedItem}
        TableComponent={TableComponent}
      />
    </section>
  )
}
