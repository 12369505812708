import { Page, Photo } from 'types'
import { CRUDContainer } from '../../../shared/CRUDContainer'
import { PhotoManagementDetail } from './PhotoManagementDetail'
import {
  deletePhoto,
  deletePhotos,
  fetchAllPages,
  fetchAllPhotos,
  fetchPhoto,
  updatePhoto,
} from '../../../shared/api'
import { PhotoManagementTable } from './PhotoManagementTable'
import { useCallback, useState } from 'react'

export interface PhotoWithUsedPages extends Photo {
  usedPages: Page[]
}

export const PhotoManagement = () => {
  // Track which photos are used on which pages in order to provide a warning to the user before deleting photos
  const [photosPageMap, setPhotosPageMap] = useState<Map<string, Set<Page>>>(
    new Map()
  )

  const fetchAllPhotosAndJoinPages = useCallback(async () => {
    const [photos, pages] = await Promise.all([
      fetchAllPhotos(),
      fetchAllPages(),
    ])

    // Create a mapping of all the photos found on each page
    const map = pages.reduce((acc, page) => {
      page.content.forEach(pageContent => {
        if (
          pageContent.type === 'GALLERY' ||
          pageContent.type === 'TRIPLE_IMAGE'
        ) {
          pageContent.photoIds.forEach(photoId => {
            if (!acc.has(photoId)) {
              acc.set(photoId, new Set<Page>())
            }
            acc.get(photoId)!.add(page)
          })
        }
      })

      return acc
    }, new Map<string, Set<Page>>())

    setPhotosPageMap(map)
    return photos.map(photo => ({
      ...photo,
      usedPages: map.has(photo.entryId)
        ? Array.from(map.get(photo.entryId)!)
        : [],
    }))
  }, [])

  return (
    <CRUDContainer<PhotoWithUsedPages>
      DetailComponent={PhotoManagementDetail}
      itemDisplayName="photo"
      itemIdKey="entryId"
      listFunction={fetchAllPhotosAndJoinPages}
      retrievalFunction={async id => {
        const photo = await fetchPhoto(id)
        return {
          ...photo,
          usedPages: photosPageMap.has(photo.entryId)
            ? Array.from(photosPageMap.get(photo.entryId)!)
            : [],
        }
      }}
      TableComponent={PhotoManagementTable}
      updateFunction={updatePhoto}
      deleteFunction={({ entryId }) => deletePhoto(entryId)}
      filterToBeDeleted={photos =>
        photos.filter(photo => photo.usedPages.length === 0)
      }
      bulkDeleteFunction={items =>
        deletePhotos(items.map(item => item.entryId))
      }
    />
  )
}
