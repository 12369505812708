import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { PastedSticker } from 'types'
import { getPastedStickers, pasteSticker } from '../api'
import { Box } from '@mui/material'
import { currentStage } from 'config'
import { useLocation } from 'react-router-dom'

const StickerContext = createContext<{
  pasteSticker: (
    stickerId: string,
    page: string,
    x: number,
    y: number
  ) => Promise<void>
}>({
  pasteSticker: async () => {},
})

const useStickers = () => useContext(StickerContext)

const StickerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [pastedStickers, setPastedStickers] = useState<PastedSticker[]>([])
  const path = useLocation().pathname

  const fetchPastedStickers = useCallback(async () => {
    const pastedStickers = await getPastedStickers()
    setPastedStickers(pastedStickers)
  }, [])

  useEffect(() => {
    fetchPastedStickers()
    const interval = setInterval(fetchPastedStickers, 5000)
    return () => clearInterval(interval)
  }, [fetchPastedStickers])

  const onPasteSticker = useCallback(
    async (stickerId: string, page: string, x: number, y: number) => {
      pasteSticker(stickerId, page, x, y)
      setPastedStickers([
        ...pastedStickers,
        { photoId: stickerId, page, x, y } as PastedSticker, // Add to array immediately, even if API fails
      ])
    },
    [pastedStickers]
  )

  return (
    <StickerContext.Provider value={{ pasteSticker: onPasteSticker }}>
      {pastedStickers
        .filter(sticker => sticker.page === path)
        .map((sticker, i) => (
          <PastedStickerComponent key={i} sticker={sticker} />
        ))}
      {children}
    </StickerContext.Provider>
  )
}

export { StickerProvider, useStickers }

function PastedStickerComponent(props: { sticker: PastedSticker }) {
  return (
    <Box
      sx={{
        position: 'absolute',
        left: props.sticker.x,
        top: props.sticker.y,
        zIndex: 999999,
      }}
    >
      <img
        src={`https://jasonandfriends-${currentStage}-pictures-bucket.s3.amazonaws.com/processed/${props.sticker.photoId}/thumbnail_${props.sticker.photoId}`}
        alt={props.sticker.photoId}
        style={{ width: '50px' }}
      />
    </Box>
  )
}
