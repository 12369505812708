import { Page } from 'types'
import {
  createTheme,
  responsiveFontSizes,
  ScopedCssBaseline,
  ThemeProvider,
} from '@mui/material'
import { useState, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { PageComponent } from '../../content/Page'
import createCache, { EmotionCache } from '@emotion/cache'
import { CacheProvider } from '@emotion/react'

export function PageLivePreview(props: { onClose: () => void; page: Page }) {
  const [containerEl, setContainerEl] = useState<HTMLDivElement | null>(null)
  const [targetWindow, setTargetWindow] = useState<Window>()

  const [emotionCache, setEmotionCache] = useState<EmotionCache>()

  useEffect(
    () => {
      // 1. Open a new, blank window
      const newWin = window.open('', '', 'width=800,height=1000')
      if (!newWin) {
        console.error('Failed to open new browser window.')
        return
      }
      newWin.document.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <title>${props.page.title}</title>
          </head>
          <body style="background-color: rgb(250, 248, 241)">
    
          </body>
        </html>
      `)
      newWin.document.close()

      // 2. Create a <div> inside the new window
      const el = newWin.document.createElement('div')
      newWin.document.body.appendChild(el)

      const cache = createCache({
        key: 'my-popup-cache',
        prepend: true,
        container: newWin.document.head,
      })
      setEmotionCache(cache)

      setContainerEl(el)

      newWin.addEventListener('beforeunload', props.onClose)

      setTargetWindow(newWin)

      return () => {
        newWin.removeEventListener('beforeunload', props.onClose)
        newWin.close()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  if (!containerEl || !emotionCache || !targetWindow) return null

  return createPortal(
    // The emotion cache provider forces the child components to recalculate css class names separate from the ones used in the main window. This is essential because otherwise, the rendered MUI elements will have generated css class names which aren't found in this window's styles.
    <CacheProvider value={emotionCache}>
      <ThemeProvider
        theme={responsiveFontSizes(
          createTheme({
            typography: {
              fontFamily: 'avenir, sans-serif',
            },
            palette: {
              background: {
                default: 'rgb(250, 248, 241)',
              },
            },
          })
        )}
      >
        <ScopedCssBaseline>
          <PageComponent
            page={props.page}
            showDate={!props.page.title.toLowerCase().includes('featured')}
            showTitle={!props.page.title.toLowerCase().includes('featured')}
            targetWindow={targetWindow}
          />
        </ScopedCssBaseline>
      </ThemeProvider>
    </CacheProvider>,
    containerEl
  )
}
