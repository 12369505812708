import {
  SelectDetailField,
  SelectDetailFieldOption,
  TextDetailField,
} from '../../../shared/DetailField'
import { DetailProps } from '../../../shared/CRUDContainer'
import { Page, PageContent } from 'types'
import { PageElementEditorField } from './pageElements/PageElementEditorField'
import { datetimeDisplay } from '../../../shared/utils'
import { CardDetailFormWrapper } from '../../../shared/CardDetail'
import { Box, Button } from '@mui/material'
import { useState } from 'react'
import { PageLivePreview } from './PageLivePreview'

const selectOptions: { [key: string]: SelectDetailFieldOption[] } = {
  publishedStatus: [
    { label: 'Draft', value: 'DRAFT' },
    { label: 'Published', value: 'PUBLISHED' },
  ],
}

function PageManagementFields(props: {
  editMode: boolean
  values: Page
  setFieldValue: (field: string, value: any) => void
  setFieldTouched: (field: string, touched?: boolean) => void
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const openModalInNewWindow = () => {
    setIsModalOpen(true)
  }

  const closeModalInNewWindow = () => {
    setIsModalOpen(false)
  }

  return (
    <Box>
      {isModalOpen && (
        <PageLivePreview onClose={closeModalInNewWindow} page={props.values} />
      )}
      <Button
        variant="contained"
        color="success"
        onClick={openModalInNewWindow}
        sx={{
          marginBottom: '20px',
        }}
        disabled={isModalOpen}
      >
        {isModalOpen
          ? 'Preview Open'
          : props.editMode
          ? 'Live Preview'
          : 'Preview'}
      </Button>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '20px',
          flexWrap: 'wrap',
        }}
      >
        {props.editMode && (
          <Box sx={{ minWidth: '200px' }}>
            <TextDetailField
              required
              editMode={props.editMode}
              label="Title"
              name="title"
            />
          </Box>
        )}
        <Box sx={{ minWidth: '200px' }}>
          <TextDetailField
            required
            editMode={props.editMode}
            label="Navigation Label"
            name="navigationLabel"
          />
        </Box>
        <Box sx={{ minWidth: '200px' }}>
          <TextDetailField
            required
            editMode={props.editMode}
            label="Navigation Link"
            name="navigationValue"
          />
        </Box>
        <Box sx={{ minWidth: '200px' }}>
          <SelectDetailField
            required
            editMode={props.editMode}
            label="Published Status"
            name="publishedStatus"
            options={selectOptions.publishedStatus}
          />
        </Box>
        {!props.editMode && (
          <Box sx={{ minWidth: '200px' }}>
            <TextDetailField
              editMode={false}
              label="Update Time"
              name="updateTime"
              formatter={datetimeDisplay}
            />
          </Box>
        )}
      </Box>

      <PageElementEditorField editMode={props.editMode} />
    </Box>
  )
}

export function PageManagementDetail(props: DetailProps<Page>) {
  return (
    <CardDetailFormWrapper<Page>
      {...props}
      // need to attach a "selected" to all page elements since that isn't saved as a DB field
      item={
        props.item
          ? {
              ...props.item,
              content: props.item.content.map((e, i) => ({
                ...e,
                selected: i === 0,
              })),
            }
          : undefined
      }
      itemName="page"
      initialValues={{ content: [] as PageContent, publishedStatus: 'DRAFT' }}
      FormFieldsComponent={PageManagementFields}
      mandatoryFields={['title', 'content']}
      // Custom validate function to take care of nested data
      formValidate={async values => {
        return values.content.reduce((errors, pageElement, i) => {
          if (!pageElement.name) {
            errors[`content#${pageElement.id}#name`] = 'Name is required'
          }
          if (pageElement.type === 'TEXT' && !pageElement.text) {
            errors[`content#${pageElement.id}#text`] = 'Text is required'
          }
          if (
            pageElement.type === 'TRIPLE_IMAGE' &&
            !pageElement.photoIds.length
          ) {
            errors[`content#${pageElement.id}#photoIds`] =
              'Three photos are required'
          }
          if (pageElement.type === 'GALLERY' && !pageElement.photoIds.length) {
            errors[`content#${pageElement.id}#photoIds`] =
              'At least one photo is required'
          }

          return errors
        }, {} as { [key: string]: any })
      }}
    />
  )
}
