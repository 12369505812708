import { currentStage } from 'config'
import { parseDomain, ParseResultType } from 'parse-domain'
import { Page, Photo } from 'types'
import { logger } from 'utilities'
import { fetchWithErrorCheck } from './utils'

export const getApiDomain = () => {
  return (
    process.env.REACT_APP_SERVICE_API ||
    (() => {
      const { host, protocol } = window.location

      const parseResult = parseDomain(host)

      if (parseResult.type === ParseResultType.Listed) {
        const { domain, topLevelDomains } = parseResult

        const consolidatedTopLevelDomain = topLevelDomains.join('.')

        const result = `${protocol}//${currentStage}.service.${domain}.${consolidatedTopLevelDomain}`

        logger.info('API domain:', result)

        return result
      } else {
        console.warn(parseResult)
        throw new Error('Domain cannot be parsed from current location.')
      }
    })()
  )
}
export const fetchAllPhotos = async (): Promise<Photo[]> => {
  const result = await fetchWithErrorCheck(`${getApiDomain()}/photos`)
  const json = await result.json()
  return json.photos
}

export const fetchPhoto = async (photoId: string): Promise<Photo> => {
  const result = await fetchWithErrorCheck(
    `${getApiDomain()}/photos/${photoId}`
  )
  return await result.json()
}

export const updatePhoto = async (photo: Photo) => {
  const result = await fetchWithErrorCheck(
    `${getApiDomain()}/photos/${photo.entryId}`,
    {
      method: 'POST',
      body: JSON.stringify(photo),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return await result.json()
}

const getUploadUrl = async (name: string) => {
  const result = await fetchWithErrorCheck(
    `${getApiDomain()}/photos/upload_uri?name=${name}`
  )
  const json = await result.json()
  return json.uploadUrl
}

export const uploadPhoto = async (name: string, file: File) => {
  const uploadUrl = await getUploadUrl(name)
  await fetchWithErrorCheck(uploadUrl, {
    method: 'PUT',
    body: file,
  })
}

export const deletePhoto = async (photoId: string): Promise<void> => {
  await fetchWithErrorCheck(`${getApiDomain()}/photos/${photoId}`, {
    method: 'DELETE',
  })
}

export const deletePhotos = async (photoIds: string[]): Promise<void> => {
  await fetchWithErrorCheck(`${getApiDomain()}/photos`, {
    method: 'DELETE',
    body: JSON.stringify({ photoIds }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const fetchAllPages = async (): Promise<Page[]> => {
  // await new Promise(resolve => setTimeout(resolve, 3000))
  const result = await fetchWithErrorCheck(`${getApiDomain()}/pages`)
  const json = await result.json()
  return json.pages
}

export const fetchPage = async (pageId: string): Promise<Page> => {
  const result = await fetchWithErrorCheck(`${getApiDomain()}/pages/${pageId}`)
  return await result.json()
}

export const createPage = async (page: Page) => {
  const result = await fetchWithErrorCheck(`${getApiDomain()}/pages`, {
    method: 'POST',
    body: JSON.stringify(page),
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return await result.json()
}

export const updatePage = async (page: Page) => {
  const result = await fetchWithErrorCheck(
    `${getApiDomain()}/pages/${page.entryId}`,
    {
      method: 'POST',
      body: JSON.stringify(page),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return await result.json()
}
export const deletePage = async (page: Page): Promise<void> => {
  await fetchWithErrorCheck(`${getApiDomain()}/pages/${page.entryId}`, {
    method: 'DELETE',
  })
}

export const deletePages = async (pageIds: string[]): Promise<void> => {
  await fetchWithErrorCheck(`${getApiDomain()}/pages`, {
    method: 'DELETE',
    body: JSON.stringify({ pageIds }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const getStickerIds = async (): Promise<string[]> => {
  return [
    'JOU_1369.jpg',
    'JOU_1379.jpg',
    'JOU_1390.jpg',
    'JOU_1405.jpg',
    'JOU_1408.jpg',
  ]

  // const result = await fetchWithErrorCheck(`${getApiDomain()}/stickers`)
  // const json = await result.json()
  // return json.stickerIds
}

export const getPastedStickers = async () => {
  const result = await fetchWithErrorCheck(`${getApiDomain()}/stickers/current`)
  const json = await result.json()
  return json.pastedStickers
}

export const pasteSticker = async (
  stickerId: string,
  page: string,
  x: number,
  y: number
) => {
  await fetchWithErrorCheck(`${getApiDomain()}/stickers`, {
    method: 'POST',
    body: JSON.stringify({ photoId: stickerId, page, x, y }),
    headers: {
      'Content-Type': 'application/json',
    },
  })
}
