import { Box, IconButton, Typography } from '@mui/material'
import { useRef, useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIsMobile } from '../../shared/utils'
import MenuIcon from '@mui/icons-material/Menu'
import { usePages } from './PageProvider'

export function Header({ children }: { children: React.ReactNode }) {
  const isMobile = useIsMobile()

  return isMobile ? (
    <MobileHeaderComponent>{children}</MobileHeaderComponent>
  ) : (
    <WebHeaderComponent>{children}</WebHeaderComponent>
  )
}

function MobileHeaderComponent({ children }: { children: React.ReactNode }) {
  const { galleries } = usePages()
  const currentPath = useLocation().pathname
  const navigate = useNavigate()

  const headerRef = useRef<HTMLDivElement>(null)
  const [headerHeight, setHeaderHeight] = useState(0)

  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight)
    }
  }, [])

  return (
    <Box>
      {/* Dynamic spacer to take into account responsive header */}
      <Box sx={{ minHeight: `${headerHeight}px` }} />

      <Box
        ref={headerRef}
        sx={{
          top: 0,
          position: 'fixed',
          backgroundColor: 'rgb(250, 248, 241)',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 10px 5px 10px',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => navigate('/featured')}
        >
          JASONANDFRIENDS
        </Typography>
        <IconButton onClick={() => setShowMenu(!showMenu)}>
          <MenuIcon />
        </IconButton>
      </Box>
      {showMenu && (
        <Box
          sx={{
            position: 'fixed',
            backgroundColor: 'rgb(250, 248, 241)',
            width: '100%',
            height: '100%',
            textAlign: 'center',
            overflow: 'hidden',
          }}
        >
          <Box>
            <Typography
              variant="h5"
              sx={{
                textDecoration:
                  currentPath === '/featured' ? 'underline' : 'none',
                textUnderlineOffset: '5px',
                cursor: 'pointer',
                marginBottom: '40px',
                marginTop: '40px',
              }}
              onClick={() => {
                setShowMenu(false)
                navigate('/featured')
              }}
            >
              Featured
            </Typography>
          </Box>

          {galleries.map(gallery => (
            <Typography
              key={gallery.entryId}
              variant="h5"
              sx={{
                textDecoration: currentPath.includes(gallery.navigationValue)
                  ? 'underline'
                  : 'none',
                textUnderlineOffset: '5px',
                cursor: 'pointer',
                marginBottom: '10px',
              }}
              onClick={() => {
                setShowMenu(false)
                navigate(`/gallery/${gallery.navigationValue}`)
              }}
            >
              {gallery.navigationLabel}
            </Typography>
          ))}
        </Box>
      )}
      {children}
    </Box>
  )
}

function WebHeaderComponent({ children }: { children: React.ReactNode }) {
  const currentPath = useLocation().pathname
  const navigate = useNavigate()

  const headerRef = useRef<HTMLDivElement>(null)
  const [headerHeight, setHeaderHeight] = useState(0)

  useEffect(() => {
    if (headerRef.current) {
      setHeaderHeight(headerRef.current.offsetHeight)
    }
  }, [])

  return (
    <Box>
      {/* Dynamic spacer to take into account responsive header */}
      <Box sx={{ minHeight: `${headerHeight}px` }} />

      <Box
        ref={headerRef}
        sx={{
          top: 0,
          position: 'fixed',
          backgroundColor: 'rgb(250, 248, 241)',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px 20px',
        }}
      >
        <Typography
          variant="h3"
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => navigate('/featured')}
        >
          JASONANDFRIENDS
        </Typography>
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <Typography
            variant="h6"
            sx={{
              textDecoration:
                currentPath === '/featured' ? 'underline' : 'none',
              textUnderlineOffset: '5px',
              cursor: 'pointer',
            }}
            onClick={() => navigate('/featured')}
          >
            Home
          </Typography>
          <Typography
            variant="h6"
            sx={{
              textDecoration: currentPath.includes('gallery')
                ? 'underline'
                : 'none',
              textUnderlineOffset: '5px',
              cursor: 'pointer',
            }}
            onClick={() => navigate('/gallery')}
          >
            Galleries
          </Typography>
        </Box>
      </Box>
      {children}
    </Box>
  )
}
