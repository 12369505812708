import { useCallback, useMemo, useRef, useState } from 'react'
import { Photo, PhotoUploadStatus } from 'types'
import { useBanner } from '../BannerProvider'
import { deletePhotos, fetchAllPhotos, uploadPhoto } from '../../shared/api'
import { debounce } from '../../shared/utils'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  FormControlLabel,
  LinearProgress,
  Switch,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { VerticalFlexGallery } from '../../shared/VerticalFlexGallery'

interface PhotoUpload {
  image: string // this is the image "src"
  photo: Photo // the metadata
  file: File // the binary file

  isDuplicate: boolean
  shouldUpload: boolean // Used for photo uploads where the file already exists in the database
  errorReason?: string
}

type PhotoUploadMap = Map<string, PhotoUpload>

export const UploadManager = () => {
  const { showBanner } = useBanner()

  const [uploads, setUploads] = useState<PhotoUploadMap>(
    new Map<string, PhotoUpload>()
  )
  // The ref is used in the interval to track the state of the uploads across each invocation of the interval, but we still need a React stateful uploads in "state" to trigger rerenders.
  const uploadsRef = useRef(new Map<string, PhotoUpload>())

  const [uploadInProgress, setUploadInProgress] = useState(false)
  const [fileSelectionInProgress, setFileSelectionInProgress] = useState(false)

  const uploadsAttempted = useRef(false)
  const failedUploads = useRef(new Set<string>())

  const updateUpload = useCallback((upload: PhotoUpload) => {
    setUploads(prevUploads => {
      const newUploads = new Map<string, PhotoUpload>(prevUploads)
      newUploads.set(upload.photo.entryId, upload)
      return newUploads
    })
  }, [])
  const removeUpload = useCallback(
    (entryId: string) =>
      setUploads(prevUploads => {
        const newUploads = new Map<string, PhotoUpload>(prevUploads)
        newUploads.delete(entryId)
        return newUploads
      }),
    []
  )

  const handleFileChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = event.target.files
      if (!fileList) return

      if (fileList.length > 50) {
        showBanner('Please upload 50 files or fewer at a time.', 'error')
        return
      }

      // Only JPEG or PNG files
      if (
        Array.from(fileList).some(
          file =>
            !['image/jpeg', 'image/png'].includes(file.type) ||
            !file.name.match(/\.(jpe?g|png)$/i)
        )
      ) {
        showBanner('Only JPEG or PNG files are currently supported.', 'error')
        return
      }

      try {
        setFileSelectionInProgress(true)
        const allPhotos = await fetchAllPhotos()
        const allPhotoEntryIds = new Set(allPhotos.map(photo => photo.entryId))

        await Promise.all(
          Array.from(fileList).map(async file => {
            const image = await new Promise<string>((resolve, reject) => {
              // We need to read the file as a data URL to display it in the UI
              const reader = new FileReader()
              reader.onload = () => {
                resolve(reader.result as string)
              }
              reader.onerror = reject
              reader.readAsDataURL(file)
            })

            const photoUpload = {
              image,
              photo: {
                entryId: file.name,
                title: file.name,
                uploadStatus: 'STARTED',
              } as Photo,
              file,
              isDuplicate: allPhotoEntryIds.has(file.name),
              shouldUpload: !allPhotoEntryIds.has(file.name), // default duplicates to no, ask user to confirm overwrite or not
            }
            updateUpload(photoUpload)
          })
        )
      } catch (e) {
        showBanner('Error reading files: ' + (e as Error).message, 'error')
        setUploads(new Map<string, PhotoUpload>())
      } finally {
        setFileSelectionInProgress(false)
      }
    },
    [showBanner, updateUpload]
  )

  const uploadFiles = useCallback(async () => {
    uploadsAttempted.current = false
    uploadsRef.current = new Map<string, PhotoUpload>()

    uploads.forEach((upload, entryId) => {
      if (upload.photo.uploadStatus !== 'COMPLETE' && upload.shouldUpload) {
        uploadsRef.current.set(entryId, upload)
      }
    })

    if (uploadsRef.current.size === 0) {
      return
    }

    // delete all the photos that are duplicates and should be overwritten, since we are going to simultaneously pull down the state of the photos periodically, and we don't want to pull down old metadata
    const duplicatesToBeDeleted = [...uploads.values()].filter(
      upload => upload.isDuplicate && upload.shouldUpload
    )

    try {
      await deletePhotos(
        duplicatesToBeDeleted.map(upload => upload.photo.entryId)
      )
    } catch (e) {
      showBanner('Error deleting duplicates', 'error')
      return
    }

    failedUploads.current = new Set<string>()
    try {
      setUploadInProgress(true)
      // No await for this yet
      const uploadPromises = Promise.allSettled(
        Array.from(uploadsRef.current).map(async ([_, upload]) => {
          try {
            await uploadPhoto(upload.photo.title as string, upload.file)

            const initialUpload: PhotoUpload = {
              ...upload,
              photo: { ...upload.photo, uploadStatus: 'UPLOADED' },
            }

            uploadsRef.current.set(upload.photo.entryId, initialUpload)
            updateUpload(initialUpload)
          } catch (error) {
            // Need to update both the ref and state always at the same time. The ref is used for the interval, and the state is used for the UI
            const failedUpload: PhotoUpload = {
              ...upload,
              errorReason: (error as Error).message,
              photo: { ...upload.photo, uploadStatus: 'UPLOAD_FAILED' },
            }

            uploadsRef.current.set(upload.photo.entryId, failedUpload)

            updateUpload(failedUpload)
            failedUploads.current.add(upload.photo.entryId)

            showBanner(
              `Error uploading photo: ${upload.photo.entryId}. Remaining photos will continue to upload.`,
              'error'
            )
            throw error
          }
        })
      )
      // This interval is responsible for fetching the latest state of the photos from the database, since processing happens asynchronously.
      // Lasts for 30 seconds, or if all uploads have failed or succeeded.
      // In order to prevent multiple uploads, loading state is set to false only when all uploads have failed or succeeded, or if the interval times out
      const updateInterval = setInterval(async () => {
        const dbPhotos = (await fetchAllPhotos()).filter(photo =>
          uploadsRef.current.has(photo.entryId)
        )

        // If all photos failed the initial upload, immediately clear the interval and the timeout
        if (uploadsRef.current.size === failedUploads.current.size) {
          clearInterval(updateInterval)
          clearInterval(processingTimeout)
          setUploadInProgress(false)
          showBanner(
            'All photos failed to upload. Please check the status of each photo.',
            'error'
          )
          return
        }

        // Because this interval is launched immediately, there is a high chance that the fetchAllPhotos call will return before the first upload is processed.
        if (dbPhotos.length === 0) return

        // TODO: inefficient loop
        uploadsRef.current.forEach(upload => {
          const dbPhoto = dbPhotos.find(
            photo => photo.entryId === upload.photo.entryId
          )

          if (dbPhoto) {
            if (dbPhoto.uploadStatus === 'PROCESSING_FAILED') {
              failedUploads.current.add(upload.photo.entryId)
            }

            const updatedUpload: PhotoUpload = {
              ...upload,
              photo: { ...upload.photo, uploadStatus: dbPhoto.uploadStatus },
            }

            uploadsRef.current.set(upload.photo.entryId, updatedUpload)
            updateUpload(updatedUpload)
          }
        })

        if (
          dbPhotos.every(
            photo =>
              photo.uploadStatus === 'COMPLETE' ||
              // Need to use a ref here because the state is not updated yet
              failedUploads.current.has(photo.entryId)
          )
        ) {
          clearInterval(updateInterval)
          clearInterval(processingTimeout)
          setUploadInProgress(false)

          if (!uploadsAttempted.current) {
            uploadsAttempted.current = true
            if (failedUploads.current.size) {
              showBanner(
                'Some photos failed to upload. Please check the status of each photo.',
                'error'
              )
            } else {
              showBanner('All photos uploaded successfully.', 'success')
            }
          }
        }
      }, 1000)
      const processingTimeout = setTimeout(() => {
        console.log(uploads, 'timed out')
        clearInterval(updateInterval)
        showBanner(
          'Processing is taking longer than expected. Please check back later.',
          'error'
        )
        // Set all remaining uploads to TIMEDOUT
        Array.from(uploadsRef.current.values())
          .filter(
            upload =>
              upload.photo.uploadStatus !== 'COMPLETE' &&
              upload.photo.uploadStatus !== 'UPLOAD_FAILED'
          )
          .forEach(upload => {
            const timedOutUpload: PhotoUpload = {
              ...upload,
              photo: { ...upload.photo, uploadStatus: 'UPLOAD_TIMED_OUT' },
            }

            uploadsRef.current.set(upload.photo.entryId, timedOutUpload)
            updateUpload(timedOutUpload)
          })
        setUploadInProgress(false)
      }, 120000)
      await uploadPromises
    } catch (error) {
      showBanner('Error uploading photos: ' + (error as Error).message, 'error')
    }
  }, [uploads, updateUpload, showBanner])

  const onShouldUploadToggle = useCallback(
    (upload: PhotoUpload) => {
      updateUpload({
        ...upload,
        shouldUpload: !upload.shouldUpload,
      })
    },
    [updateUpload]
  )

  const bulkToggleStatus = useMemo(() => {
    if (uploads.size === 0) {
      return false
    }

    const firstDuplicate = [...uploads.values()].find(
      upload => upload.isDuplicate
    )

    return firstDuplicate ? firstDuplicate.shouldUpload : false
  }, [uploads])
  const bulkToggleShouldUpload = useCallback(() => {
    setUploads(prevUploads => {
      const newUploads = new Map<string, PhotoUpload>(prevUploads)
      newUploads.forEach(upload => {
        upload.shouldUpload = !bulkToggleStatus
      })
      return newUploads
    })
  }, [bulkToggleStatus])

  return (
    <UploadContainer
      uploads={uploads}
      onFileChange={handleFileChange}
      uploadFiles={uploadFiles}
      onShouldUploadToggle={onShouldUploadToggle}
      bulkToggleStatus={bulkToggleStatus}
      bulkToggleShouldUpload={bulkToggleShouldUpload}
      uploadInProgress={uploadInProgress}
      removeUpload={removeUpload}
      fileSelectionInProgress={fileSelectionInProgress}
    />
  )
}

export const UploadContainer = ({
  uploads,
  onFileChange,
  removeUpload,
  uploadFiles,
  onShouldUploadToggle,
  bulkToggleStatus,
  bulkToggleShouldUpload,
  uploadInProgress,
  fileSelectionInProgress,
}: {
  uploads: PhotoUploadMap
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>
  removeUpload: (entryId: string) => void
  uploadFiles: () => Promise<void>
  onShouldUploadToggle: (upload: PhotoUpload) => void
  bulkToggleStatus: boolean
  bulkToggleShouldUpload: () => void
  uploadInProgress: boolean
  fileSelectionInProgress: boolean
}) => {
  const { totalUploads, totalDuplicates, totalDuplicatesToBeOverwritten } =
    useMemo(
      () =>
        [...uploads.values()]
          .filter(upload => upload.photo.uploadStatus !== 'COMPLETE')
          .reduce(
            (acc, upload) => {
              acc.totalUploads += 1
              if (upload.isDuplicate) {
                acc.totalDuplicates += 1
                if (upload.shouldUpload) {
                  acc.totalDuplicatesToBeOverwritten += 1
                }
              }
              return acc
            },
            {
              totalUploads: 0,
              totalDuplicates: 0,
              totalDuplicatesToBeOverwritten: 0,
            }
          ),
      [uploads]
    )

  return (
    <section style={{ marginTop: '10px' }}>
      <Box sx={{ marginBottom: '8px' }}>
        <LoadingButton
          component="label"
          loading={uploadInProgress || fileSelectionInProgress}
        >
          Choose Photos
          <input type="file" hidden onChange={onFileChange} multiple />
        </LoadingButton>
        {uploads.size > 0 && (
          <LoadingButton
            component="label"
            variant="contained"
            loading={uploadInProgress || fileSelectionInProgress}
            onClick={() => {
              debounce(uploadFiles, 200)()
            }}
            disabled={
              !totalUploads ||
              (totalUploads === totalDuplicates &&
                totalDuplicatesToBeOverwritten === 0)
            }
          >
            Upload
          </LoadingButton>
        )}
      </Box>

      {uploads.size > 0 && (
        <Box>
          {totalDuplicates > 0 && (
            <FormControlLabel
              control={
                <Switch
                  checked={bulkToggleStatus}
                  onChange={bulkToggleShouldUpload}
                />
              }
              label="Overwrite all duplicates"
            />
          )}

          <Box sx={{ marginBottom: '16px' }}>
            <Chip
              label={`${totalUploads} files selected`}
              sx={{ marginRight: '8px' }}
            />
            <Chip
              label={`${totalDuplicates} duplicates selected`}
              sx={{ marginRight: '8px' }}
            />
            <Chip
              label={`${totalDuplicatesToBeOverwritten} duplicates to be overwritten`}
            />
          </Box>
        </Box>
      )}

      <VerticalFlexGallery
        columnWidth={300}
        components={[...uploads.values()].map(upload => (
          <Upload
            key={upload.photo.entryId}
            upload={upload}
            removeUpload={() => removeUpload(upload.photo.entryId)}
            onShouldUploadToggle={() => onShouldUploadToggle(upload)}
            uploadInProgress={uploadInProgress}
          />
        ))}
      />
    </section>
  )
}

export const Upload = (props: {
  upload: PhotoUpload
  onShouldUploadToggle: () => void
  removeUpload: () => void
  uploadInProgress: boolean
}) => {
  const backgroundColorMapping = {
    STARTED: 'white',
    UPLOADED: 'white',
    UPLOAD_FAILED: 'lightcoral',
    UPLOAD_TIMED_OUT: 'lightcoral',
    PENDING: 'white',
    EXIF_PARSED: 'white',
    RESIZING_FINISHED: 'white',
    PROCESSING_FAILED: 'lightcoral',
    COMPLETE: 'lightgreen',
  }

  return (
    <Card
      sx={{
        backgroundColor:
          backgroundColorMapping[props.upload.photo.uploadStatus],
      }}
    >
      <CardContent>
        <Typography sx={{ fontSize: 16 }} gutterBottom>
          {props.upload.photo.title}
        </Typography>
        {((props.uploadInProgress && props.upload.shouldUpload) ||
          props.upload.photo.uploadStatus !== 'STARTED') && (
          <UploadProgress status={props.upload.photo.uploadStatus} />
        )}

        {props.upload.isDuplicate &&
          props.upload.photo.uploadStatus !== 'COMPLETE' && (
            <FormControlLabel
              control={
                <Switch
                  disabled={props.uploadInProgress}
                  checked={props.upload.shouldUpload}
                  onChange={props.onShouldUploadToggle}
                />
              }
              label="Overwrite existing photo"
            />
          )}
      </CardContent>
      <img
        src={props.upload.image}
        alt={`Uploaded file ${props.upload.photo.title}`}
        style={{ width: '100%', height: 'auto' }}
      />

      {!props.uploadInProgress &&
        props.upload.photo.uploadStatus === 'STARTED' && (
          <CardActions>
            <Button size="small" color="primary" onClick={props.removeUpload}>
              Remove
            </Button>
          </CardActions>
        )}
    </Card>
  )
}

function UploadProgress({ status }: { status: PhotoUploadStatus }) {
  let value = 0
  switch (status) {
    case 'STARTED':
      value = 16.66
      break
    case 'UPLOADED':
      value = 33.32
      break
    case 'UPLOAD_FAILED':
      value = 33.32
      break
    case 'UPLOAD_TIMED_OUT':
      value = 33.32
      break
    case 'PENDING':
      value = 50
      break
    case 'EXIF_PARSED':
      value = 66.66
      break
    case 'RESIZING_FINISHED':
      value = 83.33
      break
    case 'PROCESSING_FAILED':
      value = 83.33
      break
    case 'COMPLETE':
      value = 100
      break
  }

  let statusText = ''
  switch (status) {
    case 'STARTED':
      statusText = 'Upload Started'
      break
    case 'UPLOADED':
      statusText = 'Uploaded'
      break
    case 'UPLOAD_FAILED':
      statusText = 'Upload Failed'
      break
    case 'UPLOAD_TIMED_OUT':
      statusText = 'Upload Timed Out'
      break
    case 'PENDING':
      statusText = 'Pending'
      break
    case 'EXIF_PARSED':
      statusText = 'EXIF Parsed'
      break
    case 'RESIZING_FINISHED':
      statusText = 'Resizing Finished'
      break
    case 'PROCESSING_FAILED':
      statusText = 'Processing Failed'
      break
    case 'COMPLETE':
      statusText = 'Complete'
      break
  }

  return (
    <Box>
      <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
        {statusText}
      </Typography>
      <LinearProgress variant="determinate" value={value} />
    </Box>
  )
}
