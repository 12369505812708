import { useCallback, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { currentStage } from 'config'
import { useStickers } from './StickerProvider'
import { getStickerIds } from '../api'
import { DraggableElement } from '../dnd'
import { useComponentSizeAndPosition } from '../utils'
import { useLocation } from 'react-router-dom'

export function StickerBar() {
  const [stickerIds, setStickerIds] = useState<string[]>([])

  const fetchStickerIds = useCallback(async () => {
    const stickerIds = await getStickerIds()
    setStickerIds(stickerIds)
  }, [])

  useEffect(() => {
    fetchStickerIds()
  }, [fetchStickerIds])

  return (
    <Box
      sx={{
        position: 'fixed',
        width: '100%',
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        zIndex: 1000,
      }}
    >
      {stickerIds.map(stickerId => (
        <StickerPickup key={stickerId} stickerId={stickerId} />
      ))}
    </Box>
  )
}

function StickerPickup(props: { stickerId: string }) {
  const { boxRef, top, left } = useComponentSizeAndPosition()
  const { pasteSticker } = useStickers()
  const [offset, setOffset] = useState({ x: 0, y: 0 })
  const path = useLocation().pathname

  return (
    <Box
      sx={{
        display: 'inline-block',
        cursor: 'grab',
      }}
      onMouseDown={e => {
        setOffset({ x: e.clientX - left, y: e.clientY - top })
      }}
      onMouseUp={e => {
        console.log({ offset })
        pasteSticker(
          props.stickerId,
          path,
          e.clientX - offset.x,
          e.clientY - offset.y
        )
      }}
      ref={boxRef}
    >
      <DraggableElement id={props.stickerId}>
        <img
          src={`https://jasonandfriends-${currentStage}-pictures-bucket.s3.amazonaws.com/processed/${props.stickerId}/thumbnail_${props.stickerId}`}
          alt={props.stickerId}
          style={{ width: '50px' }}
        />
      </DraggableElement>
    </Box>
  )
}
