import { Box, Typography } from '@mui/material'
import { currentStage } from 'config'
import { useNavigate } from 'react-router-dom'
import { usePages } from './PageProvider'
import { useCallback, useEffect, useState } from 'react'
import { GalleryPageElement } from 'types'
import { Spinner } from './Spinner'

export function Splash() {
  const navigate = useNavigate()

  const { featured, loading } = usePages()

  const [selectedImageId, setSelectedImageId] = useState<string>()
  const [isFading, setIsFading] = useState(false) // Tracks fading state

  const randomizeSplashImage = useCallback(() => {
    if (featured) {
      const imageIds = (featured.content[0] as GalleryPageElement).photoIds
      const randomIndex = Math.floor(Math.random() * imageIds.length)
      setSelectedImageId(
        (featured.content[0] as GalleryPageElement).photoIds[randomIndex]
      )
    }
  }, [featured])

  useEffect(() => {
    let interval: NodeJS.Timeout

    if (featured) {
      randomizeSplashImage()
      interval = setInterval(() => {
        // Trigger fade-out first
        setIsFading(true)
        setTimeout(() => {
          randomizeSplashImage()
          setIsFading(false) // Fade back in after image changes
        }, 300) // Match this to your CSS transition duration
      }, 10000)
    }

    return () => clearInterval(interval)
  }, [featured, randomizeSplashImage])

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        key: selectedImageId,
      }}
    >
      <Typography
        variant="h1"
        onClick={() => navigate('/featured')}
        sx={{ cursor: 'pointer' }}
      >
        JASONANDFRIENDS
      </Typography>
      {loading ? (
        <Spinner />
      ) : (
        <Box
          sx={{
            position: 'relative',
            display: 'inline-block', // Ensures the box wraps tightly around the image
            width: '100%',
            maxWidth: '1500px',
            padding: '0 30px',
            cursor: 'pointer',
          }}
          onClick={() => navigate('/featured')}
        >
          <img
            src={`https://jasonandfriends-${currentStage}-pictures-bucket.s3.amazonaws.com/processed/${selectedImageId}/medium_${selectedImageId}`}
            alt={selectedImageId}
            style={{
              width: '100%',
              display: 'block',
              opacity: isFading ? 0 : 1, // Use opacity for fading
              transition: 'opacity 0.3s ease-in-out', // Smooth fade effect
            }}
          />
          <Typography
            variant="h4"
            onClick={() => navigate('/featured')}
            sx={{
              position: 'absolute',
              bottom: '40px', // Aligns to the bottom edge of the image
              left: '50%',
              transform: 'translateX(-50%)', // Centers the text horizontally
              backgroundColor: 'rgba(0, 0, 0, 0.3)', // Optional: adds a background for contrast
              color: 'white', // Optional: ensures the text is visible
              padding: '10px', // Optional: adds some spacing around the text
            }}
          >
            EXPLORE
          </Typography>
        </Box>
      )}
    </Box>
  )
}
