import { useMemo } from 'react'
import { Box } from '@mui/material'
import { useComponentSizeAndPosition } from './utils'

export function VerticalFlexGallery({
  columnWidth,
  components,
  targetWindow = window,
}: {
  targetWindow?: Window
  columnWidth: number
  components: Array<JSX.Element>
}) {
  const { boxRef, width } = useComponentSizeAndPosition(targetWindow)

  const columnCount = (() => {
    const initial = Math.floor(width ? width / columnWidth : 1)
    return initial > 0 ? initial : 1
  })()

  // Split the elements into columns
  const columns = useMemo(() => {
    const columnArray: JSX.Element[][] = Array.from(
      { length: columnCount },
      () => []
    )
    Object.values(components).forEach((component, index) => {
      columnArray[index % columnCount].push(component)
    })
    return columnArray
  }, [components, columnCount])

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
      }}
      ref={boxRef}
    >
      {columns.map((column, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            gap: '10px',
          }}
        >
          {column.map((component, index) => (
            <Box key={index} sx={{ margin: '0px', padding: '0px' }}>
              {component}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  )
}
